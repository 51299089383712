import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as _ from 'lodash';
import {combineLatest, iif, Observable, of, Subscription} from 'rxjs';
import {debounceTime, map, mergeMap, shareReplay, switchMap, tap} from 'rxjs/operators';
import {environment} from '@env';
import {AdvSearchArgs, DicoCarac, NPElementType} from '@nextpage/np-sdk-data';
import {ParamsFilterBuilder} from '@data/builders';
import {Chars, SortType} from '@data/types';
import {MatSelectChange} from '@angular/material/select';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {NpInstanceService} from '@data/services';
import {ExportBaseComponent} from 'src/app/graphics/components/base/export-base.component';
import {MatButtonToggleChange} from '@angular/material/button-toggle';
import {ElementReaderService} from 'src/app/data/services/element-reader.service';
import {NPElement} from 'src/lib/data/model/np-element';
import {selectAdvancedSearchParamsScope, UpdateScope} from '@store/table';
import {selectChannelScopeExtId} from 'src/app/store/selector/user-info.selectors';
import {FetchProductsElements, FetchProductsElementsLoader, selectProductsElements} from '@store/products';
import {selectDataLanguage} from 'src/app/store/data-languages';
import {Router} from '@angular/router';
import {FetchProductCharTemplates, selectProductCharTemplate} from '@store/product-char-template';
import {FilterInterface} from 'src/app/core/interfaces/filter-interface';
import {CharTemplateDto, ParamsFilter} from '@app/models';
import {
  CharTemplateFiltersComponent
} from 'src/app/graphics/components/char-template-filters/char-template-filters.component';
import {
  CONSULTATION_DEFAULT_PAGE_INDEX,
  CONSULTATION_DEFAULT_PAGE_SIZE
} from 'src/app/data/constants/products-page-size.constants';
import {ElementSelectionService} from '@data/services';
import {selectIsNotSelectAllModel} from '@data/selection';
import {FavoriteActions} from '@store/favorites';
import { FetchProductsElementsFailure } from '@store/products';
import { CleanAdvandedSearchParamsStore, InitDataTable } from 'src/app/store/table';

@Component({
  selector: 'app-products-page',
  templateUrl: './products-page.component.html',
  styleUrls: ['./products-page.component.scss'],
})
export class ProductsPageComponent extends ExportBaseComponent implements OnInit, OnDestroy, FilterInterface {
  public dataView$ = this._store.select(selectProductsElements);

  public exportBtnLabelKey$ = this._store.select(selectIsNotSelectAllModel)
      .pipe(map(isSelectNotAll => isSelectNotAll ? 'products.export-selection' : 'products.export-all'));

  public charTemplatesView$ = this._store.select(selectProductCharTemplate);
  public defaultChannelScope: { scopeId: number | null; isChannel: boolean };
  public paramsFilterBuilder: ParamsFilterBuilder;
  public selectedDataLanguageID: number;
  public channelCategoriesList$ = this._getChannelCategoriesList();
  public customClass: string;
  public toggle = true;
  public channelCategorySelected: NPElement;
  public pageSize = CONSULTATION_DEFAULT_PAGE_SIZE;
  public columnsToDisplay: string[];

  private readonly _elementType: NPElementType = environment.instance.elementType;
  private readonly elementType = environment.instance.elementType;

  private _dataExportSub: Subscription;
  private _scopeIdSub: Subscription;


  @ViewChild('searchFilter') searchFilter!: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(CharTemplateFiltersComponent) charTemplateFiltersComponent: CharTemplateFiltersComponent;

  constructor(
      private _instanceService: NpInstanceService,
      private _elementReaderService: ElementReaderService,
      private _router: Router,
      private readonly _selectionService: ElementSelectionService
  ) {
    super();

    this.columnsToDisplay = ['select', 'overview', 'label', 'status', 'modificationDate', 'actions'];

    this.paramsFilterBuilder = new ParamsFilterBuilder()
        .withNumberOfElementByPage(this.pageSize)
        .withElementTypes([this.elementType]);
  }

  ngOnInit(): void {
    this._store.dispatch(InitDataTable());

    this._store.dispatch(FetchProductCharTemplates());

    this.getElementsFromScope();

    this.customClass = this._instanceService.getStyleCass();

    this.searchFormControlSub = this.resetSearchField
        .subscribe(() => {
          this.searchByKeyword('');
        });
  }

  public sortData(params: Partial<SortType>) {
    this.paramsFilterBuilder.withSortFields(params);

    this.dispatchSearchElements(null, this.paramsFilterBuilder.build());
  }

  public searchByKeyword(searchedValue: string): void {
    const paramsFilter = this.paramsFilterBuilder.withKeyword(searchedValue ?? '');
    if (this.channelCategorySelected) {
      paramsFilter.withChannelScope(this.channelCategorySelected.ID);
    }
    paramsFilter.withPage(CONSULTATION_DEFAULT_PAGE_INDEX);

    this.paginator?.firstPage();
    this.dispatchSearchElements(null, paramsFilter.build());
  }

  public resetSearch(event: Event): void {
    this.searchFilter.nativeElement.value = '';
    this.searchByKeyword(event.target['value']);
  }

  public handlePageEvent($event: PageEvent): void {
    const paramsFilter = this.paramsFilterBuilder
      .withPage($event.pageIndex + 1)
      .withNumberOfElementByPage($event.pageSize)
      .build();

    this.dispatchSearchElements(null, paramsFilter);
  }

  public selectChannelCategory(event: MatSelectChange): void {
    this.channelCategorySelected = event.value;
    this.paginator?.firstPage();
    this.charTemplateFiltersComponent.resetAllSelectedFilters();

    this.resetProductFilters({isChannel: true, scopeId: this.channelCategorySelected.ID});

    this._store.dispatch(UpdateScope({
      scopeId: this.channelCategorySelected.ID,
      isChannel: true
    }));

    this.charTemplateFiltersComponent.filtersFacets([], null);

    this.dispatchSearchElements(null, this.paramsFilterBuilder.build());
  }

  public clearChannelCategoriesFilter(): void {
    this.channelCategorySelected = null;
    this.paginator?.firstPage();

    this.charTemplateFiltersComponent.resetData(this.defaultChannelScope);

    this._store.dispatch(FetchProductsElementsLoader());
    this._store.dispatch(FetchProductsElements({paramsFilter: this.paramsFilterBuilder.build()}));

    this._store.dispatch(UpdateScope(this.defaultChannelScope));
  }

  public toggleView(change: MatButtonToggleChange) {
    this.toggle = change.value;
  }

  public exportAllProducts() {
    this._dataExportSub?.unsubscribe();

    this._dataExportSub = this._selectionService
        .getSelectedElementIds(this.paramsFilterBuilder, [DicoCarac.PRODUCT_LABEL])
        .pipe(mergeMap(elementIds => this.exportElements$(elementIds, this._router)))
        .subscribe(() => {
          this._dataExportSub?.unsubscribe();
        });
  }

  private _getChannelCategoriesList(): Observable<NPElement[]> {
    return this._store.select(selectChannelScopeExtId)
      .pipe(
          switchMap(channelExtId => {
          return iif(() =>
            !!channelExtId,
            this._elementReaderService.getDescendants(channelExtId, false, [DicoCarac.CHANNEL_NODE_LABEL, DicoCarac.CHANNEL_LABEL])
              .pipe(map((parent: NPElement) => parent?.Children || [])),
            of([])
          );
        }
        )
      );
  }

  private getElementsFromScope(): void {
    // Select scope (channel/channelNode/familyId)
    this._scopeIdSub = combineLatest([
      this._store.select(selectAdvancedSearchParamsScope),
      this._store.select(selectDataLanguage)
    ])
      .subscribe(([scope, selectedDataLang]) => {
        this.defaultChannelScope = scope;

        if (scope.selectedScope && scope.selectedScope.scopeId > 0) {
          this.paramsFilterBuilder.withScope(scope.selectedScope);
          this.selectedDataLanguageID = selectedDataLang.ID;
          if (!!selectedDataLang) {
            this.paramsFilterBuilder.withLangID(this.selectedDataLanguageID);
          }

          this.dispatchSearchElements(null, this.paramsFilterBuilder.build());
        } else {
          this._store.dispatch(FetchProductsElementsFailure({ message: null }))
        }
      });
  }

  dispatchSearchElements(advancedSearch?: AdvSearchArgs, paramsFilter?: ParamsFilter) {
    this._store.dispatch(FetchProductsElementsLoader());
    this._store.dispatch(FetchProductsElements({paramsFilter: paramsFilter}));
  }

  filterByProductType(productType: CharTemplateDto) {
    const paramsFilter = this.paramsFilterBuilder
        .withProductTypeId(productType.ID)
        .withElementTypes([this._elementType])
        .withPage(CONSULTATION_DEFAULT_PAGE_INDEX)
        .withChars(new Chars())
        .build();

    this.paginator?.firstPage();
    this.dispatchSearchElements(null, paramsFilter);
  }

  resetProductFilters(scope?: { scopeId: number, isChannel: boolean }) {
    if (_.isNil(scope)) {
      scope = this.channelCategorySelected ? {
        isChannel: true,
        scopeId: this.channelCategorySelected.ID
      } : this.defaultChannelScope;
    }

    this.paramsFilterBuilder
        .withScope(scope)
        .withChars(new Chars())
        .withProductTypeId(null)
        .withPage(CONSULTATION_DEFAULT_PAGE_INDEX);

    this.dispatchSearchElements(null, this.paramsFilterBuilder.build());
  }

  searchProducts() {
    this.dispatchSearchElements(null, this.paramsFilterBuilder.build());
  }

  onToggleAllFavorites(isToggleAllFavorites: boolean) {
    this._store.dispatch(
        FavoriteActions.toggleFavorites({
          paramsFilterBuilder: this.paramsFilterBuilder,
          isToggleAllFavorites: isToggleAllFavorites
        })
    );
  }

  ngOnDestroy(): void {
    this._dataExportSub?.unsubscribe();
    this._scopeIdSub?.unsubscribe();
    this.searchFormControlSub?.unsubscribe();

    this._store.dispatch(UpdateScope(this.defaultChannelScope));
    this._store.dispatch(CleanAdvandedSearchParamsStore());
  }
}
