import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {FormControl, FormsModule, ReactiveFormsModule, RequiredValidator, Validators} from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatCheckboxChange, MatCheckboxModule} from '@angular/material/checkbox';
import {AsyncPipe, NgClass, NgForOf, NgIf} from '@angular/common';
import {MatExpansionModule} from '@angular/material/expansion';
import {ThumbsService} from '@data/services';
import {map, mergeMap, takeUntil} from 'rxjs/operators';
import {ExportHelper} from '../../../data/helpers/export.helper';
import {NpInstanceService} from '@data/services';
import {DesignComponent} from '../base/design.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { combineLatest, Subject } from 'rxjs';
import { ExportParams, LanguageOptions } from 'src/app/data/models/export.params.model';
import { Store } from '@ngrx/store';
import { GetAllExportProfiles, selectExportProfileState } from '@store/exportProfiles';
import { ProfilDto } from '@lib/models';
import {  MatSelectModule } from '@angular/material/select';
import {  MatOptionModule } from '@angular/material/core';
import { selectDataLanguages } from '@store/data-languages';
import { NpLanguageDto } from 'src/lib/data/model/np-language-dto';

@Component({
    selector: 'app-dialog-elements-export',
    templateUrl: './dialog-elements-export.component.html',
    styleUrls: ['./dialog-elements-export.component.scss'],
    imports: [
        MatDialogModule,
        MatButtonModule,
        FormsModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        NgForOf,
        MatExpansionModule,
        NgIf,
        AsyncPipe,
        NgClass,
        TranslateModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatOptionModule
    ],
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogElementsExportComponent extends DesignComponent implements OnInit, OnDestroy {
    emailFormControl = new FormControl('', [
        Validators.email
    ]);

    profileFormControl = new FormControl('', [
        Validators.required
    ]);


    oneFileByLangControl = new FormControl(false);

    insertPictureIntoControl = new FormControl(false);

    protected initialized  = false;
    protected loading = false;
    protected profiles: ProfilDto[];
    protected errorMessage?: string;
    protected exportProfileUnsupported = false;

    protected configurationView: ExportParams;

    protected availableLanguages: NpLanguageDto[] = [];
    protected selectedLanguages: NpLanguageDto[] = [];
    

    protected readonly displayMediaBlock = !inject(NpInstanceService).isMediaLibraryPage();


    private _destroySubject = new Subject<void>();

    constructor(private _translate: TranslateService,
        private _thumbService: ThumbsService,
        private _store: Store,
        private _changeDetector: ChangeDetectorRef
    ) {
        super();
        
    }

    ngOnInit(): void {
        this._initializeConfigurationView();

        this._store.select(selectExportProfileState)
            .pipe(
                takeUntil(this._destroySubject)
            )
            .subscribe(
                (state)=> {
                    if (state?.initialized){
                        this.initialized = true;
                        this.loading = state.loading;
                        
                        if (!this.loading){
                            this.errorMessage = state.errorMessage;
                            this.profiles = state.profiles;

                            if (!this.errorMessage && !this.profiles?.length){
                                this.errorMessage = 'enums.messages.exportProfileEmpty';
                            }
                            else {
                                this.profiles.sort( (a,b)=>{
                                    return a.ExtID.toLocaleUpperCase().localeCompare(b.ExtID.toLocaleUpperCase());
                                });

                                if (this.profiles.length ===1){
                                    this.profileFormControl.patchValue(this.profiles[0].ExtID);
                                    this.profileFormControl.disable();
                                }
                                else {
                                    this.profileFormControl.enable();
                                }
                            }
                        }

                        this.exportProfileUnsupported = state.unsupported;
                        if (this.exportProfileUnsupported){
                            delete this.errorMessage;
                        }
                    }
                    else {
                        this.initialized = false;
                        this._store.dispatch(GetAllExportProfiles());
                    }
                    this._changeDetector.markForCheck();
                }
            );
    }

    ngOnDestroy(): void {
        this._destroySubject.next();
        this._destroySubject.complete();
    }

    private _initializeConfigurationView() {
        combineLatest(
            [
                this._thumbService.getAllowedThumbsSizes(),
                this._store.select(selectDataLanguages)
            ]
        )
            .pipe(
                takeUntil(this._destroySubject),

                map(([allowedThumbsSizes, languageState]) => {
                    this.availableLanguages = languageState.dataLanguages.slice();

                    if (this.availableLanguages?.length && !this.selectedLanguages?.length && languageState.selectedDataLanguage){
                        this.selectedLanguages = [];

                        this.availableLanguages.every( (current)=> {
                            let res = true;
                            if (current.ID === languageState.selectedDataLanguage.ID){
                                this.selectedLanguages.push(current);
                                res = false;
                            }
                            return res;
                        });
                    }

                    const exportMediaParam = ExportHelper.buildExportMediaParam(allowedThumbsSizes, this._translate.instant('products.export-thumbnails'));
                    const languageOptions: LanguageOptions = {
                        langIds: [languageState.selectedDataLanguage?.ID || 0],
                        oneFileByLang: false
                    };
                    const exportParams = new ExportParams(languageOptions, exportMediaParam, this.emailFormControl.value);

                    if (!this.exportProfileUnsupported &&  this.profileFormControl.value){
                        exportParams.exportProfile = this.profileFormControl.value;
                    }
                    return exportParams;
                })
            )
            .subscribe(
                (param)=> {
                    this.configurationView = param;
                    this._changeDetector.markForCheck();
                }
            );

        this.emailFormControl.valueChanges.subscribe(email => {
            this.configurationView.emailParam = email;
        });

        this.profileFormControl.valueChanges.subscribe( extId => {
            if (!this.exportProfileUnsupported && this.configurationView){
                this.configurationView.exportProfile = extId;
            }
        });

        this.oneFileByLangControl.valueChanges.subscribe( oneFile => {
            if (this.configurationView){
                this.configurationView.languageOptions.oneFileByLang = oneFile;
            }
        });

        this.insertPictureIntoControl.valueChanges.subscribe( insertPictures => {
            if (this.configurationView){
                this.configurationView.picturesInExcel = insertPictures;
            }
        });
    }


    isLangSelected(lang: NpLanguageDto){
        const retValue = this.selectedLanguages.indexOf(lang)>= 0;
        return retValue;
    }



    selectLang(event: MatCheckboxChange | Event, lang: NpLanguageDto) {
        const index = this.selectedLanguages.indexOf(lang);
        if (event['checked']) {
            if (index === -1){
                this.selectedLanguages.push(lang);
            }
        } else if (index != -1){
            this.selectedLanguages.splice(index, 1);
        }
        this.configurationView.languageOptions.langIds = [];
        this.selectedLanguages.forEach( (current)=> {
            this.configurationView.languageOptions.langIds.push(current.ID);
        })
        this._changeDetector.markForCheck();
    }
    
}
