import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, debounceTime, exhaustMap, map, mergeMap, switchMap} from 'rxjs/operators';
import {iif, of, throwError} from 'rxjs';
import {Store} from '@ngrx/store';
import {
    FetchAdvancedSearchParamsFailure,
    FetchAdvancedSearchParamsSuccess,
    InitDataTable,
    UpdateScope,
    UpdateScopeSuccess
} from './advanced-search-params.actions';
import {DicoCarac, ElementRepository} from '../../../lib';
import {selectChannelScopeExtId, selectFamilyScopeExtId} from '../selector/user-info.selectors';
import { FetchElementsFailure } from '../elements';
import * as _ from 'lodash';

@Injectable()
export class AdvancedSearchParamsEffect {

    constructor(
        private actions$: Actions,
        private _store: Store,
        private _elementRepository: ElementRepository
    ) {
    }

    intAdvancedSearchParams$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InitDataTable),
            switchMap(() => {
                return this._store.select(selectChannelScopeExtId) // Channel scope
                .pipe(
                    debounceTime(500),
                    map((scopeExtId) => {
                        return ({scopeExtId: scopeExtId, isChannel: true})
                    })
                ).pipe(
                    mergeMap(scope => 
                        !!scope && !!scope.scopeExtId ?
                            of(scope) :
                            this._store.select(selectFamilyScopeExtId) // Family scope
                                .pipe(map(scopeExtId => ({scopeExtId: scopeExtId, isChannel: false})))
                    )
                )}
            ),
            exhaustMap((scope) => {
                return iif(() => !!scope.scopeExtId, this._getScopeId(scope.scopeExtId), throwError('products.channel-error'))
                    .pipe(
                        map(scopeId => {
                            if(!_.isNil(scopeId)) {
                                return FetchAdvancedSearchParamsSuccess({ scopeId, isChannel: scope.isChannel })
                            } else {
                                this._store.dispatch(FetchAdvancedSearchParamsFailure({ message: 'products.channel-error' }));
                                return FetchElementsFailure({ message: 'products.channel-error' });
                            }
                        }),
                        catchError(error => {
                            this._store.dispatch(FetchAdvancedSearchParamsFailure({ message: error?.toString() }));
                            return of(FetchElementsFailure({ message: error.toString() }));
                        })
                    );
            })
        );
    });

    updateScope$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UpdateScope),
            map((action) => {
                return UpdateScopeSuccess({scopeId: action.scopeId, isChannel: action.isChannel})
            })
        );
    });

    private _getScopeId = (scopeExtId: string) =>
        this._elementRepository.getElement(
            scopeExtId,
            [[]],
            [DicoCarac.CHANNEL_LABEL, DicoCarac.CHANNEL_NODE_LABEL, DicoCarac.FAMILY_LABEL]
        ).pipe(map(scope => scope ? scope.ID : null))
}
