<div class="d-flex justify-content-between align-items-center">
  <h2 mat-dialog-title>
    {{options.title  | translate}}
  </h2>
</div>
<mat-dialog-content class="dialog-content">
  <span [innerHTML]="options.message  | translate"></span>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-end dialog-actions">
  <ng-container *ngFor="let action of actions">
    <button
      mat-flat-button
      [color]="action.color || ''"
      [title]="(action.title | translate)|| ''"
      [ngClass]="action.classes" (click)="actionClicked(action)">
      {{ action.label  | translate }}
    </button>
  </ng-container>
</mat-dialog-actions>
