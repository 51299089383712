import {Component} from '@angular/core';
import {UploadImage} from '../../../../lib/graphics/np-media-library/np-media-library.component';
import {Store} from '@ngrx/store';
import {
    AddMedias,
    createMediaByExternalUrl,
    RemoveMedia,
    selectIsUploaded,
    selectUploadImages,
    UploadFiles,
} from '@store/import-media';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-import-media-page',
    templateUrl: './import-media-page.component.html',
    styleUrls: ['./import-media-page.component.scss'],
})

export class ImportMediaPageComponent {

    public uploadImages$: Observable<UploadImage[]> = this._store.select(selectUploadImages);
    public dropZoneHovered = false;
    public externalUrlMedia: string;
    public isUploaded$ = this._store.select(selectIsUploaded);

    constructor(
        private _store: Store) {
    }

    public onFileChange(event) {
        const files = event.target.files;
        if (files.length) {
            this.handleDrop(files);
        }
    }

    handleHover($event: boolean) {
        this.dropZoneHovered = $event;
    }

    public handleDrop(file: FileList) {
        this._store.dispatch(AddMedias({medias: Array.from(file).map(_file => new UploadImage(_file))}));
    }

    uploadAllFiles() {
        this._store.dispatch(UploadFiles());
    }

    removeFile(uploadImage: UploadImage, event?: Event) {
        event?.stopPropagation();
        this._store.dispatch(RemoveMedia({media: uploadImage}));
    }

    createMediaByExternalUrl() {
        this._store.dispatch(createMediaByExternalUrl({url: this.externalUrlMedia}));
    }
}
