import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { FavoriteActions, selectFavoritesCount } from '@store/favorites';
import { RoutePagesName } from 'src/app/data/constants/route-pages.constants';
import { RouteResolverService } from 'src/app/data/services/routes/route-resolver.service';

@Component({
  selector: 'app-favorite-selector',
  templateUrl: './favorite-selector.component.html',
  styleUrls: ['./favorite-selector.component.scss']
})
export class FavoriteSelectorComponent {
  public favoritesState = this.store.select(selectFavoritesCount);

  constructor(private _routeResolverService: RouteResolverService, private store: Store) {
  }  

  goToFavorite() {
    this.store.dispatch(FavoriteActions.loadElementsFromFavorite());
    return this._routeResolverService.navigateToCurrentEntityRoute(RoutePagesName.FAVORITE);
  }
}
