import {createReducer, on} from '@ngrx/store';
import {ProductSummary} from 'src/app/data/models';
import * as _ from 'lodash';
import {environment} from 'src/environments/environment';
import {FavoriteActions} from './favorite.actions';

export const favoriteFeatureKey = 'favorite';

export interface FavoriteState {
    favorites: Partial<ProductSummary>[];
    favoriteExtIds: string[];
    errorMessage: string | null;
    loading: boolean;
    hasError: boolean;
    hasFavorites: boolean;
}

export const initialStateFavorite: FavoriteState = {
    favorites: [],
    favoriteExtIds: JSON.parse(localStorage.getItem(environment.favoriteKey))?.map(favorite => favorite?.extID),
    errorMessage: null,
    loading: true,
    hasError: false,
    hasFavorites: false
};

export const FavoriteReducer = createReducer(
    initialStateFavorite,
    on(FavoriteActions.loadElementsFromFavoriteSuccess, (state: FavoriteState, {favorites}) => ({
        ...state,
        favorites: favorites,
        favoriteExtIds: JSON.parse(localStorage.getItem(environment.favoriteKey))?.map(favorite => favorite?.extID),
        errorMessage: null,
        loading: false,
        hasError: false,
        hasFavorites: !_.isEmpty(state.favorites)
    })),
    on(FavoriteActions.clearFavoritesSuccess, (state: FavoriteState) => ({
            ...state,
            favorites: [],
            favoriteExtIds: [],
            errorMessage: null,
            loading: false,
            hasError: false,
            hasFavorites: false
        })
    ),
    on(FavoriteActions.updateFavorites, (state: FavoriteState, {favoriteExtIds}) => {
        return ({
            ...state,
            favoriteExtIds: favoriteExtIds,
            favorites: favoriteExtIds.map(favoriteExtId => state.favorites.find(favorite => favorite.element.ExtID === favoriteExtId)).filter(item => !!item)
        });
    }),
    on(FavoriteActions.loadElementsFromFavoriteFailure,
        (state, {message}) => ({
            ...state,
            favorites: [],
            favoriteExtIds: [],
            errorMessage: message,
            loading: false,
            hasError: true,
            hasFavorites: false
        })
    ),
    on(FavoriteActions.countFavorites,
        (state, {favoriteExtIds}): FavoriteState => {
            return ({
                ...state,
                favoriteExtIds: favoriteExtIds
            });
        }
    ),
    on(FavoriteActions.startLoading,
        (state): FavoriteState => ({
            ...state,
            loading: !_.isEmpty(state.favoriteExtIds)
        })
    )
);
