<div class="full-panel" [ngClass]="customClass$ | async">
  <ng-container *ngIf="!initialized && !loading; else loaded">
    <p>{{ "loading" | translate }}</p>
  </ng-container>

  <ng-template #loaded>
    <ng-container *ngIf="configurationView && !errorMessage; else error">
      <div mat-dialog-content class="dialog-content">
        <p class="title">{{ "products.about-export-data" | translate }}</p>

        <div class="scrollable">
          <div class="margin-panel">
            <!-- Sélection du profil si dispo -->
            <mat-form-field
              *ngIf="!exportProfileUnsupported"
              appearance="outline"
              class="w-100 mt-2 profile-field">
              <mat-label>{{ "products.export-profile" | translate }}</mat-label>
              <mat-select [formControl]="profileFormControl">
                <mat-option *ngFor="let prof of profiles"
                  [value]="prof.ExtID">{{
                  prof.ExtID
                  }}</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Paramètre des vignettes -->
            <ng-container>
              <mat-slide-toggle
                [(ngModel)]="configurationView.mediasParams.checked"
                color="primary"
                tabIndex="-1">{{ "products.include-media" | translate }}
              </mat-slide-toggle>
              <mat-expansion-panel
                [expanded]="configurationView.mediasParams.checked"
                hideToggle
                class="mat-elevation-z0">
                <ul>
                  <li
                    *ngFor="let parameter of configurationView.mediasParams.parameters">
                    <mat-checkbox [(ngModel)]="parameter.checked"
                      color="primary">
                      {{ parameter.label }}
                    </mat-checkbox>
                  </li>
                </ul>
              </mat-expansion-panel>
            </ng-container>

            <!-- Image dans excel-->
            <div class="lang-options">
              <mat-slide-toggle [formControl]="insertPictureIntoControl"
                color="primary">{{ "common.fields.insert-picture-into-excel" |
                translate }}
              </mat-slide-toggle>
              <mat-expansion-panel expanded="false"></mat-expansion-panel>
            </div>

            <!-- Autre mail -->
            <mat-slide-toggle #customEmail color="primary" tabIndex="-1">{{
              "products.send-specific-recipient" | translate }}
            </mat-slide-toggle>
            <mat-expansion-panel
              [expanded]="customEmail.checked"
              hideToggle
              class="mat-elevation-z0">
              <mat-form-field appearance="outline"
                class="w-100 mt-2 email-field">
                <mat-label>{{ "common.fields.email" | translate }}</mat-label>
                <input type="email" matInput [formControl]="emailFormControl" />
                <mat-error *ngIf="emailFormControl.invalid">{{
                  "products.invalid-email" | translate
                  }}</mat-error>
              </mat-form-field>
            </mat-expansion-panel>

            <!-- Gestion langues -->
            <fieldset *ngIf="availableLanguages?.length > 1">
              <legend>{{ "common.fields.languages" | translate }}</legend>

              <div *ngFor="let lang of availableLanguages">
                <mat-checkbox
                  (change)="selectLang($event, lang)"
                  [checked]="isLangSelected(lang)"
                  color="primary">
                  <span>{{ lang.LibelleLong }}</span>
                </mat-checkbox>
              </div>

              <div class="lang-options" *ngIf="selectedLanguages?.length > 1">
                <mat-slide-toggle [formControl]="oneFileByLangControl"
                  color="primary">{{ "common.fields.one-file-by-language" |
                  translate }}
                </mat-slide-toggle>
              </div>
            </fieldset>
          </div>
        </div>
      </div>

      <mat-dialog-actions>
        <button mat-button [mat-dialog-close]="null">
          {{ "common.cancel" | translate }}
        </button>
        <button
          class="buttonConnection"
          color="primary"
          mat-raised-button
          [mat-dialog-close]="configurationView"
          [disabled]="
            (customEmail.checked && emailFormControl.invalid) ||
            (!exportProfileUnsupported && profileFormControl.invalid) ||
            (!selectedLanguages || selectedLanguages.length === 0)
          "
          [title]="'products.email-send' | translate">
          {{ "products.export" | translate }}
        </button>
      </mat-dialog-actions>
    </ng-container>
  </ng-template>

  <!-- Boite en cas d'erreur-->
  <ng-template #error>
    <div mat-dialog-content class="dialog-content">
      <p class="title">{{ "common.error" | translate }}</p>

      <mat-error class="errorMessage">{{ errorMessage | translate }}</mat-error>
    </div>

    <mat-dialog-actions>
      <button mat-button [mat-dialog-close]="null">
        {{ "common.close" | translate }}
      </button>
    </mat-dialog-actions>
  </ng-template>
</div>
