import { inject, Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { debounceTime, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { DialogService } from 'src/app/graphics/services/dialog.service';
import { FetchElementsFailure } from '@store/elements';
import { FetchProductsElementsFailure } from '@store/products';
import { AdvancedSearchFailure } from '@store/action';
import { PublicationChaptersActions } from '@store/publication';

@Injectable()
export class ErrorMessagesEffect {

    fetchElementsFailure = createEffect(
        (actions$ = inject(Actions), dialogService = inject(DialogService)) => {
            return actions$.pipe(
                ofType(FetchElementsFailure),
                debounceTime(500),
                tap((action: any) => {
                    if(action.message) {
                        dialogService.showErrorMessage(action.message, undefined, 'common.close');
                    }
                })
            );
        },
        { functional: true, dispatch: false },
    );

    fetchProductsElementsFailure = createEffect(
        (actions$ = inject(Actions), dialogService = inject(DialogService)) => {
            return actions$.pipe(
                ofType(FetchProductsElementsFailure),
                debounceTime(500),
                tap((action: any) => {
                    if(action.message) {
                        dialogService.showErrorMessage(action.message, undefined, 'common.close');
                    }
                })
            );
        },
        { functional: true, dispatch: false },
    );

    advancedSearchFailure = createEffect(
        (actions$ = inject(Actions), dialogService = inject(DialogService)) => {
            return actions$.pipe(
                ofType(AdvancedSearchFailure),
                debounceTime(500),
                tap((action: any) => {
                    if(action.message) {
                        dialogService.showErrorMessage(action.message, undefined, 'common.close');
                    }
                })
            );
        },
        { functional: true, dispatch: false },
    );

    fetchPublicationChaptersFailure = createEffect(
        (actions$ = inject(Actions), dialogService = inject(DialogService)) => {
            return actions$.pipe(
                ofType(PublicationChaptersActions.fetchPublicationChaptersFailure),
                debounceTime(500),
                tap((action: any) => {
                    if(action.message) {
                        dialogService.showErrorMessage(action.message, undefined, 'common.close');
                    }
                })
            );
        },
        { functional: true, dispatch: false },
    );
}
