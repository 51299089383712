import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ParamsFilterBuilder} from '@data/builders';
import {environment} from '@env';
import {NpInstanceService} from '@data/services';
import {Store} from '@ngrx/store';
import {FetchElements, FetchElementsLoader, selectElementsForm, CleanElementsStore} from '@store/elements';
import {
    FetchDashboardAdvancedSearches,
    FetchDashboardAdvancedSearchesLoader, FetchDashboardAdvancedSearchesSuccess,
    selectDashboardQueries,
    selectDashboardQueriesLoader,
    CleanDashboardStore
} from '@store/dashboard';
import {selectAdvancedSearchParamsScope, CleanAdvandedSearchParamsStore} from '@store/table';
import {combineLatest, Subscription} from 'rxjs';
import {SearchExtensionComponent} from 'src/app/graphics/utils/search-extension.component';
import * as _ from 'lodash';
import { DialogService } from 'src/app/graphics/services/dialog.service';

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent extends SearchExtensionComponent implements OnInit, OnDestroy {
    @ViewChild('searchFilter') searchFilter!: ElementRef;

    public readonly dataView$ = this._store.select(selectElementsForm);

    public readonly dashboardQueriesView$ = this._store.select(selectDashboardQueries);
    public readonly dashboardQueriesLoaderView$ = this._store.select(selectDashboardQueriesLoader);

    public customClass: string;
    public hasKeyword = false;

    private readonly elementType = environment.instance.elementType;
    private paramFilterBuilder: ParamsFilterBuilder;
    private _scopeIdSub: Subscription;

    constructor(
        private _instanceService: NpInstanceService,
        private _store: Store,
        private _dialogService: DialogService,
    ) {
        super();

        this.paramFilterBuilder = new ParamsFilterBuilder()
            .withNumberOfElementByPage(10)
            .withElementTypes([this.elementType]);
    }

    ngOnInit(): void {       
        this.customClass = this._instanceService.getStyleCass();
        this.getElementsFromScope();

        this.searchFormControlSub = this.resetSearchField
            .subscribe(() => {
                this.searchByKeyword('');
            });

    }

    searchByKeyword(searchedValue: string): void {
        const paramsFilter = this.paramFilterBuilder
            .withKeyword(searchedValue ?? '')
            .build();
        this._store.dispatch(FetchElementsLoader());
        this._store.dispatch(FetchElements({paramsFilter: paramsFilter}));
        this.hasKeyword = !!searchedValue;
    }

    public resetSearch(event: Event): void {
        this.searchFilter.nativeElement.value = '';
        this.searchByKeyword(event.target['value']);
    }

    private getElementsFromScope(): void {
        // Récupération des recherches avancées du dashboard
        this._store.dispatch(FetchDashboardAdvancedSearchesLoader());
        this._store.dispatch(FetchDashboardAdvancedSearches());

        // Select scope (channel/channelNode/familyId)
        this._scopeIdSub = combineLatest([
            this._store.select(selectAdvancedSearchParamsScope),
            this.dashboardQueriesView$
        ])
            .subscribe(([scope, queries]) => {
                // If no dashboard queries, display default dashboard with top 10 element
                if (!queries?.hasAdvancedSearches) {
                    this.paramFilterBuilder.withScope({isChannel: scope.isChannel, scopeId: scope.scopeId});
                    this._store.dispatch(FetchElementsLoader());
                    this._store.dispatch(FetchElements({paramsFilter: this.paramFilterBuilder.build()}));
                }
            });
    }

    ngOnDestroy(): void {
        this._scopeIdSub?.unsubscribe();
        this.searchFormControlSub?.unsubscribe();
        this._store.dispatch(FetchDashboardAdvancedSearchesSuccess({advancedSearches: []}));
        this._store.dispatch(CleanElementsStore());
        this._store.dispatch(CleanDashboardStore());
        this._store.dispatch(CleanAdvandedSearchParamsStore());
    }
}
