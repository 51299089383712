import { ProfilDto } from "@lib/models";
import { createAction, props } from "@ngrx/store";

export const GetAllExportProfiles = createAction('[export profiles] Get all profiles');

export const GetAllExportProfilesSuccess = createAction('[export profiles] Get all profiles success',
    props<{
        profiles: ProfilDto[]
    }>()
);

export const GetAllExportProfilesFailure = createAction('[export profiles] Get all profiles failure',
    props<{
        message: string;
        unsupported?: boolean
    }>()
);