import { ExportMediasParam } from "./export-medias.param.model";

export interface LanguageOptions {
    langIds: number[];
    oneFileByLang: boolean;
}

export class ExportParams {
    languageOptions: LanguageOptions;
    mediasParams: ExportMediasParam;
    emailParam: string;
    exportProfile?: string;
    picturesInExcel?: boolean;    



    constructor(langOptions: LanguageOptions, mediasParams?: ExportMediasParam, emailParam?: string) {
        this.languageOptions = langOptions;
        this.emailParam = emailParam || '';
        this.mediasParams = mediasParams || new ExportMediasParam('');
    }
}
