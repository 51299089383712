import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { PublicationChaptersActions } from '@store/publication';
import { selectPublicationChannelScopeExtId } from '../../selector/user-info.selectors';
import { Store } from '@ngrx/store';
import { ElementReaderService } from 'src/app/data/services/element-reader.service';
import { DicoCarac, NPElement } from 'src/lib';
import { DialogService } from 'src/app/graphics/services/dialog.service';

@Injectable()
export class PublicationChaptersEffect {
  constructor(
    private actions$: Actions,
    private _store: Store,
    private _elementReaderService: ElementReaderService,
    private _dialogService: DialogService,
  ) {
  }

  fetchPublicationChapters$ = createEffect(() => this.actions$.pipe(
    ofType(PublicationChaptersActions.fetchPublicationChapters),
    switchMap(() => {
      return this._store.select(selectPublicationChannelScopeExtId).pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(channelExtId => {
          if (!channelExtId) {
            return of(PublicationChaptersActions.fetchPublicationChaptersFailure({ message: 'publication.homepage.channel-error' }));
          }
  
          return this._elementReaderService.getDescendants(
            channelExtId, 
            true, 
            [DicoCarac.CHANNEL_NODE_LABEL, DicoCarac.CHANNEL_LABEL]
          ).pipe(
            map((parent: NPElement) => parent?.Children || []),
            map(chapters => {
              if (chapters.length > 0) {
                return PublicationChaptersActions.fetchPublicationChaptersSuccess({ chapters });
              } else {
                return PublicationChaptersActions.fetchPublicationChaptersFailure({ message: 'publication.homepage.channel-error' });
              }
            }),
            catchError(error => of(PublicationChaptersActions.fetchPublicationChaptersFailure({ message: error.message })))
          );
        })
      );
    })
  ));
}
