import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {ProductSummary} from '@app/models';
import {ParamsFilterBuilder} from '@data/builders';
export const FavoriteActions = createActionGroup({
    source: 'favorite',
    events: {
        'load elements from favorite': emptyProps(),
        'load elements from favorite success': props<{ favorites: Partial<ProductSummary>[] }>(),
        'clear favorites': emptyProps(),
        'clear favorites success ': emptyProps(),
        'update favorites': props<{ favoriteExtIds: string[]}>(),
        'toggle favorite': props<{ favorites: Partial<ProductSummary> }>(),
        'toggle favorites': props<{ paramsFilterBuilder: ParamsFilterBuilder, isToggleAllFavorites: boolean}>(),
        'load elements from favorite failure': props<{ message: string }>(),
        'count favorites': props<{ favoriteExtIds: string[] | null}>(),
        'toggle all favorites success ': emptyProps(),
        'toggle all favorites failure ': props<{ message: any}>(),
        'start loading ': emptyProps(),
    },
});
