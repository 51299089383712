import {Injectable} from '@angular/core';
import {environment} from '@env';
import {NpInstanceConfiguration} from '../models/np-instance-model';
import {Constantes} from '@nextpage/np-sdk-data';
import {NpEntity} from '../models/np-entity.models';
import {RoutePortalsName} from '../constants/route-portals.constants';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter} from 'rxjs/internal/operators';
import {EntityName} from '../../../lib/data/model/portal';
import {map} from 'rxjs/operators';
import { RoutePagesName } from '../constants/route-pages.constants';

interface InstancePage {
    page: RoutePortalsName | RoutePagesName;
}

@Injectable({
    providedIn: 'root'
})
export class NpInstanceService {
    private _instanceId: string;
    private _instance: NpInstanceConfiguration;
    private _currentEntity: NpEntity;
    private _instancePage: InstancePage;
    private _instancePage$ = new BehaviorSubject<InstancePage>(null);
    private _instance$ = new BehaviorSubject<NpInstanceConfiguration>(null);

    constructor(private _constants: Constantes) {
    }

    set instanceId(value: string) {
        this._instanceId = value;
    }

    get instanceId(): string {
        return this._instanceId;
    }

    get instance(): NpInstanceConfiguration {
        return this._instance;
    }

    set instance(value: NpInstanceConfiguration) {
        this._instance = value;
        environment.instance = this._instance;
        environment.favoriteKey = `FavoriteList:${this._instance?.npBaseUrl}${this._instance?.instanceId}/${this._currentEntity?.name}`;
        this._constants.baseUrl = this._instance.npBaseUrl;
        this._instance$.next(this._instance);
    }

    getInstance$(): Observable<NpInstanceConfiguration> {
        return this._instance$.asObservable();
    }

    getBgImageInstance() {
        return this.getInstance$().pipe(
            map(instance => (
                {'background-image': `url("../assets/images/${instance?.bgImageName || 'bg-nextpage-default.jpg'}")`}
            ))
        );
    }

    get currentEntity(): NpEntity {
        return this._currentEntity;
    }

    isConsultationPage() {
        return this.currentEntity.name === EntityName.Consultation;
    }

    isSupplierPage() {
        return this.currentEntity.name === EntityName.Supplier;
    }

    isMediaLibraryPage() {
        return this.currentEntity.name === EntityName.MediaLibrary;
    }

    isPublicationPage() {
        return this.currentEntity.name === EntityName.Publication;
    }

    set currentEntity(value: NpEntity) {
        this._currentEntity = value;
        environment.favoriteKey = `FavoriteList:${this._instance?.npBaseUrl}${this._instance?.instanceId}/${this._currentEntity?.name}`;
    }


    /**
     * Check if current entity is allowed
     * @param currentEntity
     */
    hasAllowedEntity(currentEntity: string) {
        return this.instance.allowedEntities.some(allowEntity => allowEntity === currentEntity);
    }

    setCurrentInstancePage(currentPage: RoutePortalsName) {
        this._instancePage = {...this._instancePage, page: currentPage};
        this._instancePage$.next(this._instancePage);
    }

    getCurrentInstancePage() {
        return this._instancePage$.asObservable()
            .pipe(
                filter(instancePage => !!instancePage)
            );
    }

    getLogoUrl() {
        return `assets/logos/${this._instance.logoName}` || 'assets/logos/default-logo.png';
    }

    getStyleCass() {
        return this._instance.styleClass || '';
    }

    getStyleCass$() {
        return this.getInstance$().pipe(map(instance => instance?.styleClass));
    }

    getLogoUrl$() {
        return this.getInstance$()
            .pipe(
                map(instance =>
                    `assets/logos/${instance?.logoName}` || 'assets/logos/default-logo.png'
                )
            );
    }

    getLoginLogoUrl$() {
        return this.getInstance$()
            .pipe(
                map(instance =>
                    `assets/logos/${instance?.loginLogoName}` || 'assets/logos/default-logo.png'
                )
            );
    }

    getTitle() {
        return this.getInstance$()
            .pipe(
                map(instance => instance?.title || 'Portail client')
            );
    }

    getFavicon() {
        return this.getInstance$()
            .pipe(
                map(instance => `assets/favicons/${instance?.favicon}` || 'assets/logos/nextpage-logo.png')
            );
    }

    setFavicon(iconUrl: string) {
        const link: HTMLLinkElement = document.querySelector('link[rel="icon"]');
        link.href = iconUrl;
        document.head.appendChild(link);
    }

    getImageByDefault() {
        return this.getInstance$()
            .pipe(
                map(instance =>
                    instance?.emptyImage ? `assets/images/${instance?.emptyImage}` : `assets/images/missing-image-default.jpg`
                )
            );
    }

    getPublicationImageTemplateByDefault() {
        return `assets/images/${this._instance.publicationDefaultImageTemplate}` || 'assets/images/missing-template-image.png';
    }
}
