import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";

export enum ConfirmationDialogResult {
    OK = 'OK',
    CANCEL = 'CANCEL',
    YES = 'YES',
    NO = 'NO',
}

export interface ConfirmationDialogAction {
    result: ConfirmationDialogResult | string;
    label: string;
    color?: 'primary' | 'secondary' | 'accent' | 'warn';
    type?: 'raised' | 'stroked' | 'flat' | 'menu';
    title?: string;
    icon?: string;
}

export interface ConfirmationDialogOptions {
    actions: ConfirmationDialogAction[];
    message: string;
    title: string;
}

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit{

    public actions: InternalConfirmationDialogAction[] = [];

    public static show(dialogService: MatDialog, options: ConfirmationDialogOptions){
        const dial = dialogService.open(ConfirmationDialogComponent, 
            {
                data: options
            });

        return dial.afterClosed();
    }

     public constructor(
        @Inject(MAT_DIALOG_DATA) public options: ConfirmationDialogOptions,
        private _dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    ){}


    ngOnInit(): void {
        
        this._buildInternalActions();
        
    }

    public actionClicked(action: ConfirmationDialogAction){
        this._dialogRef.close(action.result);
    }

    private _buildInternalActions(){
        this.actions = [];
        this.options.actions?.forEach( (currentAction)=> {
            const classes = this._getClassesFromActionType(currentAction);

            const temp: InternalConfirmationDialogAction = Object.assign({}, currentAction, {classes: classes});
            this.actions.push(temp);
        });
    }

    private _getClassesFromActionType(action: ConfirmationDialogAction){
        let retValue = '';
        if (action){
            switch(action.type){
               case 'raised':{
                    retValue = 'mat-mdc-button mat-mdc-raised-button';
                    break;
               }
               case 'stroked': {
                retValue = 'mdc-button--outlined mat-mdc-outlined-button';
                break;
               }
               case 'flat': {
                retValue = 'mat-mdc-unelevated-button';
                break;
               }
               case 'menu': {
                retValue = 'mat-mdc-menu-item mdc-list-item';
                break;
               }
            }
        }
        return retValue;
    }
}

interface InternalConfirmationDialogAction extends ConfirmationDialogAction{
    classes: string;
}