import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, debounceTime, map, switchMap} from 'rxjs/operators';
import {combineLatest, of} from 'rxjs';
import {Store} from '@ngrx/store';
import {
    FetchDashboardAdvancedSearches,
    FetchDashboardAdvancedSearchesFailure,
    FetchDashboardAdvancedSearchesSuccess
} from './dashboard.actions';
import {ProductsFacade} from '@data/facades';
import {selectElementPreviewConfigs} from '@store/selector';
import {AdvSearchArgs, NPElementType, SearchRepository} from '@nextpage/np-sdk-data';
import {AdvSearchArgsBuilder} from '@data/builders';
import * as _ from 'lodash';
import {selectDataLanguage} from '@store/data-languages';
import {NpInstanceService} from '@data/services';
import {elementTypeNamesByCode, getIncompatibleAdvSearches} from '@np/utils';
import {DialogService} from '../../graphics/services/dialog.service';
import {TranslateService} from '@ngx-translate/core';
import { InitDataTable } from '@store/table';
import { FetchElementsFailure } from '@store/elements';

@Injectable()
export class DashboardEffect {
    constructor(private actions$: Actions,
                private _productsFacade: ProductsFacade,
                private _searchRepository: SearchRepository,
                private _instance: NpInstanceService,
                private _translate: TranslateService,
                private _dialogService: DialogService,
                private _store: Store,) {
    }

    fetchAdvancedSearches$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FetchDashboardAdvancedSearches),
            switchMap(() => this._store.select(selectElementPreviewConfigs)
                .pipe(debounceTime(500))),
            switchMap(dashboardPreviewParams => {
                return this._store.select(selectDataLanguage)
                    .pipe(map(selectedDataLang => ({selectedDataLang, dashboardPreviewParams})));
            }),
            switchMap(result => {
                if(_.isNil(result.dashboardPreviewParams?.mainVisualCaracExtId) ||_.isEmpty(result.dashboardPreviewParams.mainVisualCaracExtId)) {
                    this._store.dispatch(FetchDashboardAdvancedSearchesFailure({message: 'main visual not found'}));
                    return of(FetchElementsFailure({message: 'products.main-visual-error'}));
                }
                const queries = this._buildAdvancedSearchQueries(
                    result.dashboardPreviewParams?.dashBoardQueries?.map(id => Number(id)) || [],
                    result.selectedDataLang?.ID,
                    [[result?.dashboardPreviewParams?.mainVisualCaracExtId]],
                    result?.dashboardPreviewParams.previewCaracs,
                );

                if(_.isEmpty(queries)) {
                    this._store.dispatch(InitDataTable());
                    return of(FetchDashboardAdvancedSearchesFailure({message: 'No queries found'}));
                } else {
                    return combineLatest(queries)
                    .pipe(
                        map((advancedSearches: AdvSearchArgs[]) => {

                            // Si portail consultation, récupération des recherches avancées incompatibles
                            if (this._instance.isConsultationPage()) {
                                // récupération des recherches avancées incompatibles par ElementType
                                const incompatibleAdvSearches = getIncompatibleAdvSearches(advancedSearches, this._instance.instance.elementType);
                                if (!_.isEmpty(incompatibleAdvSearches)) {
                                    this._openErrorDialog(incompatibleAdvSearches);
                                    return FetchDashboardAdvancedSearchesSuccess({advancedSearches: null});
                                }
                            }

                            return FetchDashboardAdvancedSearchesSuccess({advancedSearches});

                        }),
                        catchError(error => {
                            return of(FetchDashboardAdvancedSearchesFailure({message: error?.toString()}));
                        })
                    );
                }}
            )
        );
    });

    private _buildAdvancedSearchQueries(advSearchIds: number[], langID: number, paths: string[][], fields: string[]) {
        return advSearchIds?.map(advSearchId =>
            this._searchRepository.getAdvancedSearch(advSearchId)
                .pipe(
                    map(advancedSearch => {
                        return new AdvSearchArgsBuilder()
                            .withPageSize(10)
                            .withCurrentPage(1)
                            .withPaths(paths)
                            .withConfig(advancedSearch)
                            .withLangID(langID)
                            .withDCExtIDs(fields)
                            .build();
                    })
                )
        );
    }


    /**
     * Ouvre le dialogue d'erreur empêche l'utilisation du portail
     * @param incompatibleAdvSearches
     * @private
     */
    private _openErrorDialog(incompatibleAdvSearches: Map<string, NPElementType[]>) {
        this._dialogService.openErrorDialog(
            {
                summary: this._translate.instant('homepage.dashboard.error-popup.element-type.subtitle'),
                detail: this._translate.instant('homepage.dashboard.error-popup.element-type.detail', {
                    elementType: this._translate.instant(`enums.element-type-name.${elementTypeNamesByCode[this._instance.instance.elementType]}`)
                }),
                iterableData: this._buildAdvSearchesErrors(incompatibleAdvSearches),
                severity: '',
                config: {
                    displayCloseButton: true
                }
            },
            'dashboard-error-popup'
        );
    }

    /**
     * Construit des messages d'erreur correspondant aux Recherches Avancées incompatibles
     * Chaque ligne du tableau correspondant à une Recherche Avancée a la forme suivante :
     * [Nom_de_la_recherche_avancée] : liste des types d'Elements incompatibles séparés par un point-virgule
     * @param incompatibleAdvSearches
     * @private
     */
    private _buildAdvSearchesErrors(incompatibleAdvSearches: Map<string, NPElementType[]>) {
        return Array
            .from(incompatibleAdvSearches.keys())
            ?.map(item =>
                `<span>${item} </span>: <span class="red">${incompatibleAdvSearches.get(item).map(elementTypeCode =>
                    this._translate.instant(`enums.element-type-name.${elementTypeNamesByCode[elementTypeCode]}`)).join('; ')}</span>`);
    }
}
