
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Chapter, CatalogPage } from "@app/models";
import { Store } from "@ngrx/store";
import { selectAddedPagesNumber } from "@store/publication";
import { CatalogueTemplateSelectionOverlayComponent } from "../catalogue-template-selection-overlay/catalogue-template-selection-overlay.component";

@Component({
  selector: 'app-catalogue-template-selection',
  templateUrl: './catalogue-template-selection.component.html',
  styleUrls: ['./catalogue-template-selection.component.scss']
})
export class CatalogueTemplateSelectionComponent implements OnInit, AfterViewInit {
  @Input() chapter: Chapter;

  @Input() page: CatalogPage;
  
  @ViewChild('catalogueTemplateSelectionOverlayComponent') catalogueTemplateSelectionOverlayComponent: CatalogueTemplateSelectionOverlayComponent;
  @ViewChild('selectTemplateButton') selectTemplateButton: ElementRef;

  private _addedPages: number;

  constructor(private _store: Store) {}

  public ngOnInit(): void {
    this._store.select(selectAddedPagesNumber).subscribe(result => {
      this._addedPages = result;
    });
  }

  public ngAfterViewInit(): void {
    Promise.resolve().then(() => {
      if(this._addedPages < 2) {
        this.selectTemplateButton.nativeElement.click();
      }
    });
  }

  public selectPageTemplate(event: Event): void {
    this.catalogueTemplateSelectionOverlayComponent.toggle(event);
  }
}
