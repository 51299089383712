<div class="d-flex align-items-center justify-content-between">
    <h3 class="m-0 page-title">{{ 'common.home' | translate }}</h3>
    <div>
        <mat-form-field appearance="outline" class="field-search-custom">
            <mat-label>{{ 'common.search' | translate }}</mat-label>
            <input type="text" matInput
                   (keyup.enter)="searchByKeyword($event.target.value)"
                   [formControl]="searchFormControl"
                   #searchFilter/>
            <img matSuffix src="./assets/icons/search.svg" class="fontawesome-icon-custom" alt="search"/>
        </mat-form-field>
        <img src="./assets/icons/x-mark.svg"
             class="fontawesome-icon-custom ml-2 cursor-pointer"
             alt="Reset"
             *ngIf="searchFilter.value !== ''"
             (click)="resetSearch($event)"/>
    </div>
</div>
<mat-divider class="my-3"></mat-divider>

<ng-container *ngIf="mediaView$ | async as view">
    <ng-container *ngIf="view.hasError"></ng-container>
    <app-loader-spinner *ngIf="view.loading else dataBlock"></app-loader-spinner>

    <ng-template #dataBlock>
        <app-media-card
        [filteredCard]="view?.data?.cards || []"
        [loading]="view.loading">
        </app-media-card>
    </ng-template>
</ng-container>
