import { Component, Input } from "@angular/core";
import { CatalogPage, Chapter } from "@app/models";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { TemplatesNames, TemplatesTags } from "@data/enums";
import { UpdateChapter } from "@store/publication";

@Component({
  selector: 'app-catalogue-page-addition',
  templateUrl: './catalogue-page-addition.component.html',
  styleUrls: ['./catalogue-page-addition.component.scss']
})
export class CataloguePageAdditionComponent {

  @Input() chapter: Chapter;

  constructor(private _store: Store) {}

  public addPageToChapter(): void {
    const newPage = new CatalogPage({
      name: `${TemplatesTags.PRODUCT} - Page`,
      type: TemplatesNames.PRODUCT,
      isEditable: true,
      products: [],
      template: null
    });
    
    if (this.chapter) {
      this.chapter.pages.push(newPage);
      
      _.remove(this.chapter.pages, (page: CatalogPage) => page.name.includes(TemplatesTags.NOTE));

      this._store.dispatch(UpdateChapter({chapter: this.chapter, pagesToAdd: 1}));
    }
  }
}
