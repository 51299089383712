import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NPElementType, SearchRepository} from '../../../lib';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, take} from 'rxjs/operators';
import {AdvancedSearchFailure, AdvancedSearchSuccess, FetchAdvancedSearch} from '../actions/advanced-search.actions';
import {Store} from '@ngrx/store';
import {selectMediasAdvancedSearchId} from '../selector/user-info.selectors';
import {of} from 'rxjs';
import { EntityName } from '@lib/models';
import { AdvancedSearchDTO } from '@data/types';

@Injectable()
export class AdvancedSearchEffect {
    constructor(private actions$: Actions,
                private _searchService: SearchRepository,
                private _store: Store) {
    }

    fetchAdvancedSearch$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FetchAdvancedSearch),
            switchMap((action) => {
                return this._store.select(selectMediasAdvancedSearchId).pipe(
                    debounceTime(0),
                    distinctUntilChanged(),
                    take(1),
                    switchMap(advancedSearchId => 
                        advancedSearchId
                            ? this._handleAdvancedSearchRequest(advancedSearchId, action)
                            : this._handleError('medias.scope-medias-error')
                    ),
                    catchError(error => this._handleError(error?.toString()))
                );
            })
        );
    });
    
    private _handleAdvancedSearchRequest(advancedSearchId: number, action: any) {
        return this._searchService.getAdvancedSearch(advancedSearchId).pipe(
            map(result => this._processSearchResult(result, action)),
            catchError(error => this._handleError(error?.toString()))
        );
    }
    
    private _processSearchResult(result: AdvancedSearchDTO, action: any) {
        if (result) {
            const currentEntityName = action.currentEntityName;
            const ElementType = result.Filters.ElementType;
    
            const advSearchIdMatchConsultationOrSupplier =
                (currentEntityName === EntityName.Consultation || currentEntityName === EntityName.Supplier) &&
                (ElementType === NPElementType.Product || ElementType === NPElementType.Reference);
    
            const advSearchIdMatchMediaLibrary =
                currentEntityName === EntityName.MediaLibrary && ElementType === NPElementType.Media;

            if (advSearchIdMatchConsultationOrSupplier || advSearchIdMatchMediaLibrary) {
                return AdvancedSearchSuccess({ advancedSearch: result });
            } else {
                return AdvancedSearchFailure({ message: 'medias.unsuitable-scope-medias' });
            }
        } else {
            return AdvancedSearchFailure({ message: 'medias.scope-medias-error' });
        }
    }
    
    private _handleError(message: string) {
        return of(AdvancedSearchFailure({ message }));
    }
}
