import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EntityName, NpUserInfoService} from '../../../lib';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {FetchAdvancedSearch, FetchUserInfo, FetchUserInfoFailure, FetchUserInfoSuccess} from '../actions';
import {of} from 'rxjs';
import {Store} from '@ngrx/store';
import {FetchAllCharacteristics} from '@store/characteristics';
import { NpInstanceService } from '@data/services';

@Injectable()
export class UserInfoEffect {
    constructor(private actions$: Actions,
                private _userInfoService: NpUserInfoService,
                private _store: Store,
                private _instanceService: NpInstanceService,) {
    }

    userInfo = createEffect(() => {
        return this.actions$.pipe(
            ofType(FetchUserInfo),
            mergeMap(() => {
                    return this._userInfoService.getCurrentUser()
                        .pipe(
                            map((users) => {
                                    const nextState = FetchUserInfoSuccess(
                                        {userInfo: users?.length > 0 ? users[0] : null}
                                    );
                                    if(this._instanceService.currentEntity.name === EntityName.MediaLibrary) {
                                        this._store.dispatch(FetchAdvancedSearch({currentEntityName: this._instanceService.currentEntity.name}));
                                    }
                                    this._store.dispatch(FetchAllCharacteristics());
                                    return nextState;
                                }
                            ),
                            catchError(error => of(FetchUserInfoFailure({message: error?.toString()})))
                        );
                }
            )
        );
    });
}
