import {Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {NpInstanceService} from '@data/services';
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {LangCode} from './data/constants/languages.constants';
import {Store} from '@ngrx/store';
import {LanguageActions} from './store/actions/language.action';
import {selectLanguage} from './store/selector/language.selector';
import { map, shareReplay } from 'rxjs/operators';
import { RoutePagesName } from './data/constants/route-pages.constants';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    @Input() instanceId: string;

    public customClass$ = this._npInstanceService.getStyleCass$();
    public isCataloguePage$ = this._npInstanceService.getCurrentInstancePage()
    .pipe(map(pageUrl  => this._npInstanceService.isPublicationPage() && pageUrl.page === RoutePagesName.CATALOGUE), shareReplay());

    private titleSub: Subscription;
    private faviconSub: Subscription;
    private availableLanguages: string[] = Object.values(LangCode);
    private defaultLangCode: string = LangCode.FR;
    private _selectLangSub: Subscription;

    constructor(
        private _titleService: Title,
        private _npInstanceService: NpInstanceService,
        private _translate: TranslateService,
        private store: Store
    ) {
        this._translate.addLangs(this.availableLanguages);
        this._translate.setDefaultLang(this.defaultLangCode);
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any): void {
        this.isCataloguePage$.subscribe(isCatalogPage => {
            if (isCatalogPage) {
                $event.returnValue = this._translate.instant('publication.catalogue.close-page-warning"');
            }
        });
    }

    ngOnInit() {
        this.store.dispatch(LanguageActions.fetchSelectedLanguage());

        this._selectLangSub = this.store.select(selectLanguage)
        .subscribe(selectLang => {
            this._translate.use(selectLang || this.defaultLangCode);
        });

        this.titleSub = this._npInstanceService.getTitle().subscribe(title => this._titleService.setTitle(title));
        this.faviconSub = this._npInstanceService.getFavicon().subscribe(favicon => this._npInstanceService.setFavicon(favicon));
    }

    ngOnDestroy() {
        this.titleSub?.unsubscribe();
        this.faviconSub?.unsubscribe();
        this._selectLangSub?.unsubscribe();
    }

}
