import { ProfilDto } from "@lib/models";
import { createReducer, on } from "@ngrx/store";
import { GetAllExportProfiles, GetAllExportProfilesFailure, GetAllExportProfilesSuccess } from "./exportProfile.actions";

export const ExportProfileFeatureKey = 'ExportProfile';

export interface ExportProfileState {
    profiles: ProfilDto[];
    initialized?: boolean;
    errorMessage: string;
    loading: boolean;
    unsupported?: boolean;
    
}

const initialExportProfileState: ExportProfileState = {
    profiles: [],
    loading: false,
    errorMessage: null
};

export const exportProfilesReducer = createReducer(
    initialExportProfileState,

    on(GetAllExportProfiles, 
        (state, ): ExportProfileState => (
            {
                ...state,
                loading: true,
                initialized: true,
                errorMessage: null
            }
        )
    ),

    on(GetAllExportProfilesSuccess, 
        (state, {profiles}): ExportProfileState => (
            {
                ...state,
                profiles: profiles,
                initialized: true,
                loading: false,
                errorMessage: null
            }
        )
    ),

    on(GetAllExportProfilesFailure, 
        (state, {message, unsupported}): ExportProfileState => (
            {
                ...state,
                profiles: [],
                initialized: true,
                loading: false,
                errorMessage: message,
                unsupported: unsupported
            }
        )
    ),
)