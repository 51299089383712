import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ExportProfileFeatureKey, ExportProfileState } from "./exportProfile.reducer";


export const selectExportProfileState = createFeatureSelector<ExportProfileState>(
    ExportProfileFeatureKey
);

 

export const selectAllExportProfiles = createSelector(
    selectExportProfileState, 
    (state)=> state.profiles
);

export const selectExportProfileError = createSelector(
    selectExportProfileState,
    (state) => state.errorMessage
);