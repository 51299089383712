<ng-container>
  <div id='product-table'>
    <table mat-table [dataSource]='dataSource' matSort multiTemplateDataRows (matSortChange)="onSortChange($event);">
        <ng-container [matColumnDef]='column' *ngFor='let column of columnsToDisplay'>
            <!-- Checkbox Header Column -->
            <ng-container *ngIf="column === 'select'">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? selectAllElements($event.checked) : null"
                                  [checked]="(exportSelectionView$ | async)?.isSelectAll">
                    </mat-checkbox>
                </th>
            </ng-container>

            <!-- Favorites Header button -->
            <ng-container *ngIf="column === 'actions'">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="actions-container">
                        {{ headerLabels[column] | translate }}
                        <button mat-icon-button
                                [matTooltip]="(isToggleAllFavorites? 'common.remove-favorites' : 'common.add-favorites') | translate"
                                (click)='toggleAllFavorites()'>
                            <mat-icon [style.color]="isToggleAllFavorites ? 'red' : ''">
                                {{isToggleAllFavorites ? 'favorite' : 'favorite_border'}}
                            </mat-icon>
                        </button>
                    </div>
                </th>
            </ng-container>

            <ng-container *ngIf="isSortableColumn(column)">
                <th mat-sort-header mat-header-cell *matHeaderCellDef>{{ headerLabels[column] | translate }}</th>
            </ng-container>
            <ng-container *ngIf="!isSortableColumn(column)">
                <th mat-header-cell *matHeaderCellDef>{{ headerLabels[column] | translate }}</th>
            </ng-container>
            <td mat-cell *matCellDef='let element'
                [title]="((column !== 'actions') ? 'common.click-more-information' : '') | translate"
                (click)="column !== 'actions' && showMoreInfos(element, $event)">
                <ng-container *ngIf="column === 'select'; else viewProductTable">
                    <mat-checkbox
                            (click)="$event.stopPropagation()"
                            (change)='addToSelection(element, $event.checked)'
                            [checked]='element.selected'>
                    </mat-checkbox>
                </ng-container>
                <ng-template #viewProductTable>
                    <ng-container *ngIf="column === 'overview'; else viewProductDetails">
                        <img [src]="element.imageUrl ? element.imageUrl : imageByDefault$ | async"
                             class='imageUrlStyle'
                     height="125"
                     width="190"
                     alt='Aperçu du média'
                />
            </ng-container>
            <ng-template #viewProductDetails>
              <app-product-table-cell-view [element]='element' [column]='column'>
              </app-product-table-cell-view>
            </ng-template>
          </ng-template>
          <ng-container *ngIf="column === 'actions'">
            <div class="d-flex">
                <ng-container *ngIf="isSupplierEntityName">
                    <button mat-icon-button class="icon-more-info" 
                            [matTooltip]="'common.edit-information' | translate"
                            (click)='editInfos(element, $event)'>
                        <img ngSrc="./assets/icons/edit.svg" class="fontawesome-icon-custom icon-eye" alt="edit" height="16"
                             width="16">
                    </button>
                </ng-container>
                <button mat-icon-button class="icon-more-info" 
                        [matTooltip]="'common.display-more-information' | translate"
                        (click)='showMoreInfos(element, $event)'>
                    <img src="./assets/icons/eye.svg" class="fontawesome-icon-custom icon-eye" alt="eye" height="16"
                         width="16">
                </button>
                <button mat-icon-button
                        [matTooltip]="((favoritesState$ | async | favorite:element) ? 'common.remove-favorites' : 'common.add-favorites') | translate"
                        (click)='toggleFavorite(element, $event)'>
                    <mat-icon [style.color]="(favoritesState$ | async | favorite:element) ? 'red' : ''">
                        {{(favoritesState$ | async | favorite:element) ? 'favorite' : 'favorite_border'}}
                    </mat-icon>
                </button>
            </div>
          </ng-container>
        </td>
      </ng-container>

        <tr mat-header-row *matHeaderRowDef='columnsToDisplay'></tr>
        <tr mat-row *matRowDef='let row; columns: columnsToDisplay'
            class='expanded-detail-element-row'>
        </tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="no-result" [attr.colspan]="columnsToDisplay.length">
              {{'products.no-data' | translate}}
            </td>
          </tr>
    </table>
      <ng-content></ng-content>
  </div>
</ng-container>
