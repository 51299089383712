import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ProductSummary } from '@app/models';
import { TABLE_HEADER_LABELS } from '@data/constants';
import { NpInstanceService } from '@data/services';
import { Store } from '@ngrx/store';
import { FavoriteActions, selectFavoritesList, selectFavoritesListLoader } from '@store/favorites';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ExportBaseComponent } from 'src/app/graphics/components/base/export-base.component';
import { DialogMediaSheetComponent } from 'src/app/graphics/components/dialog-media-sheet/dialog-media-sheet.component';
import { ConfirmationDialogComponent, ConfirmationDialogResult } from 'src/app/graphics/components/misc/confirmation-dialog.component';
import { EntityName } from 'src/lib/data/model/portal';

@Component({
    selector: 'app-favorite-page',
    templateUrl: './favorite-page.component.html',
    styleUrls: ['./favorite-page.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class FavoritePageComponent extends ExportBaseComponent implements OnInit {
    public readonly favoritesListLoaderView$ = this.store.select(selectFavoritesListLoader);

    public favoritesState: Observable<Partial<ProductSummary>[]>;
    public dataFavoriteToExport: Partial<ProductSummary>[];
    public columnsToDisplay: string[];
    public headerLabels = TABLE_HEADER_LABELS;
    public dataSource: MatTableDataSource<Partial<ProductSummary>>;

    @ViewChild('searchFilter') searchFilter!: ElementRef;
    @ViewChild(MatPaginator) paginator: MatPaginator;
 
 
    constructor(private store: Store,
                public dialog: MatDialog,
                private _instanceService: NpInstanceService,
                private _router: Router) {
        super();

        this.favoritesState = this.store.select(selectFavoritesList).pipe(tap(data => {
            this.dataFavoriteToExport = data;
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.paginator = this.paginator;
        }));
        this.columnsToDisplay = ['overview', 'label', 'actions'];
    }

    ngOnInit(): void {
        this.store.dispatch(FavoriteActions.startLoading());
        this.store.dispatch(FavoriteActions.loadElementsFromFavorite());
    }

    removeFromFavoriteList(item) {
        this.store.dispatch(FavoriteActions.toggleFavorite({
            favorites: {
                extID: item.element.ExtID
            }
        }));
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue ? filterValue.trim().toLowerCase() : '';

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    public resetSearch(event: Event): void {
        this.searchFilter.nativeElement.value = '';
        this.applyFilter(event);
    }

    showMoreInfos(card) {
        if (this._instanceService.currentEntity.name === EntityName.MediaLibrary) {
            this.dialog.open(DialogMediaSheetComponent, {
                hasBackdrop: true,
                disableClose: false,
                height: '95%',
                width: '90%',
                data: card
            });
        } else {
            this._dialogService.openProductDialog(card);
        }
    }

    openExportDialog() {
        this.exportElements(
            this.dataFavoriteToExport.map(element => element.element.ID),
            this._router,
        );
    }

    toggleAllFavorites(){
        ConfirmationDialogComponent.show(this.dialog, {
            actions: [
                {
                    result: ConfirmationDialogResult.CANCEL,
                    label: 'common.cancel',
                    type: 'stroked'
                },
                {
                    result: ConfirmationDialogResult.OK,
                    label: 'common.ok',
                    color: 'primary',
                    type: 'raised'
                }
            ],
            message: 'favorites.delete-all-confirmation',
            title: 'favorites.delete-all-title',
        })
            .subscribe(
                (result: ConfirmationDialogResult | string)=> {
                    if (result === ConfirmationDialogResult.OK){
                        this.store.dispatch(FavoriteActions.clearFavorites());
                    }
                }
            )
        
    }
}
