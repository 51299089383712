import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import {
  GetAllExportProfiles,
  GetAllExportProfilesFailure,
  GetAllExportProfilesSuccess,
} from "./exportProfile.actions";
import { NpImportExportProfilService } from "@nextpage/np-sdk-data";
import { catchError, map, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class ExportProfileEffect {
  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _importExportService: NpImportExportProfilService
  ) {}

  public getAllExportProfiles = createEffect(() => {
    return this._actions$.pipe(
      ofType(GetAllExportProfiles),
      mergeMap(() => {
        return this._importExportService.getExportProfiles().pipe(
          map((profiles) => {
            return GetAllExportProfilesSuccess({ profiles });
          })
        );
      }),
      catchError((error: Error) => {
        let unsupported = false;
        const status = (error as HttpErrorResponse).status;
        if (status !== undefined && status === 0){
          unsupported = true;
        } 
        return of(
          GetAllExportProfilesFailure({
            message: error?.message || "Erreur lors de la récupération des profiles",
            unsupported: unsupported
          })
        );
      })
    );
  });
}
