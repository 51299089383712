export class ProfilDto {
    ID = 0;
    Type: number;
    ExtID: string;
    TransfoIniConfig: string;
    PostTraitementConfig: any;
    AnalysisConfig?: any;
    UserID: number;
    name?: string;
}
