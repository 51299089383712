<div class="d-flex align-items-center justify-content-between">
  <h3 class="m-0 page-title">{{ "favorites.title" | translate }}</h3>
  <div class="mat-form-field-text">
    <mat-form-field appearance="outline" class="field-search-custom">
      <mat-label>{{ "common.search" | translate }}</mat-label>
      <input type="text" matInput (keyup)="applyFilter($event)" #searchFilter />
      <img
        matSuffix
        src="./assets/icons/search.svg"
        class="fontawesome-icon-custom"
        alt="search"
      />
    </mat-form-field>
    <img
      src="./assets/icons/x-mark.svg"
      class="fontawesome-icon-custom ml-2 cursor-pointer"
      alt="Reset"
      *ngIf="searchFilter.value !== ''"
      (click)="resetSearch($event)"
    />
  </div>
</div>
<mat-divider class="my-3"></mat-divider>
<app-loader-spinner
  *ngIf="favoritesListLoaderView$ | async"
></app-loader-spinner>

<ng-container *ngIf="favoritesState | async as favoritesView">
  <div *ngIf="favoritesView.length > 0; else noFavorite">
    <div class="d-flex justify-content-end mb-3 export-button">
      <button
        class="buttonConnection"
        color="primary"
        mat-raised-button
        (click)="openExportDialog()"
      >
        <i class="pr-1 pi pi-download"></i>
        {{ "common.export" | translate }}
      </button>
    </div>
    <div class="mat-elevation-z2" style="overflow-y: auto">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
        <ng-container
          [matColumnDef]="column"
          *ngFor="let column of columnsToDisplay"
        >
          <th mat-header-cell *matHeaderCellDef>
            <div class="actions-container">
              {{ headerLabels[column] | translate }}
              <ng-container *ngIf="column === 'actions'">
                <button
                  mat-icon-button
                  [style.color]="'red'"
                  [matTooltip]="'common.remove-favorites' | translate"
                  (click)="toggleAllFavorites()"
                >
                  <mat-icon>favorite</mat-icon>
                </button>
              </ng-container>
            </div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [title]="
              (column !== 'actions' ? 'common.click-more-information' : '')
                | translate
            "
            (click)="column !== 'actions' && showMoreInfos(element)"
          >
            <ng-container *ngIf="column === 'overview'">
              <img
                *ngIf="element?.imageUrl"
                [src]="element.imageUrl"
                class="imageUrlStyle"
                height="125"
                alt="Aperçu du média"
              />
            </ng-container>
            <ng-container *ngIf="column === 'label'">
              {{ element.label }}
            </ng-container>
            <ng-container *ngIf="column === 'actions'">
              <div class="d-flex">
                <button
                  mat-icon-button
                  class="icon-more-info"
                  [matTooltip]="'common.display-more-information' | translate"
                  (click)="showMoreInfos(element); $event.stopPropagation()"
                >
                  <img
                    src="./assets/icons/eye.svg"
                    class="fontawesome-icon-custom icon-eye"
                    alt="eye"
                    height="16"
                    width="16"
                  />
                </button>
                <button
                  mat-icon-button
                  [matTooltip]="'common.remove-favorites' | translate"
                  (click)="removeFromFavoriteList(element)"
                >
                  <mat-icon style="color: red">favorite</mat-icon>
                </button>
              </div>
            </ng-container>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: columnsToDisplay"
          class="expanded-detail-element-row"
        ></tr>
      </table>

      <mat-paginator
        [pageSize]="10"
        [length]="(favoritesState | async).length"
        [pageSizeOptions]="[10, 25, 50, 100]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>

  <ng-template #noFavorite>
    <div *ngIf="!(favoritesListLoaderView$ | async)" class="noCardsAvailable">
      {{ "favorites.no-favorites" | translate }}
    </div>
  </ng-template>
</ng-container>
